.panel-profile {
  .panel-title {
    margin-bottom: 5px;
  }
  .panel-heading {
    height: 150px;
    background-size: cover;
  }
}

.panel-profile-img {
  max-width: 100px;
  margin-top: -70px;
  margin-bottom: 5px;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,.1);
}

.panel-menu {
  list-style: none;
  padding: 0;
}
.panel-menu-item {
  display: inline-block;
  padding: 0 10px;
  border-right: 1px solid @gray-lighter;
}
.panel-menu-item:last-child {
  border-right: 0;
}
